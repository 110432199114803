<template>
  <!-- <n-card> -->
    <n-space justify="space-between">
      <n-space>
        <n-button @click="prev()">
          <template #icon>
            <n-icon>
              <Left />
            </n-icon>
          </template>
        </n-button>
        <n-button @click="next()">
          <template #icon>
            <n-icon>
              <Right />
            </n-icon>
          </template>
        </n-button>
      </n-space>
      <n-space>
        <n-button @click="showModal = true" type="error" dashed>
          <template #icon>
            <n-icon>
              <Alert />
            </n-icon>
          </template>
        </n-button>
        <n-modal v-model:show="showModal">
          <n-card style="width: 600px;" title="Sistemos pagalba" :bordered="true" size="huge">
            Šiame lauke galite parašyti bei išsiųsti sistemos administratoriui pranešimą.
            Pranešimą siųskite radę klaidą ar susidūrę su kitais nesklandumais.
            <br><br>
            <b>Tema:</b> {{ store.getters['menu/getCurrentMenuData'].tema }} <br>
            <b>Potemė:</b> {{ store.getters['menu/getCurrentMenuData'].poteme }} <br>
            <b>Vartotojo id:</b> {{ store.getters['auth/getAuthData'].userId }} <br>
            <b>Vartotojo vardas:</b> {{ store.getters['auth/getAuthData'].userName }} <br>
            <b>Vartotojo el. paštas:</b> {{ store.getters['auth/getAuthData'].email }} <br>
            <br>

            <n-input type="textarea" v-model:value="userMessage" placeholder="Jūsų žinutė / klaidos apibūdinimas" />
            <br><br>
            <n-spin size="small" :show="disableSendButton">
              <n-button @click="sendUserMessage">Siųsti</n-button>
            </n-spin>
          </n-card>
        </n-modal>
      </n-space>
    </n-space>
  <!-- </n-card> -->
</template>

<script>
import Left from '@vicons/ionicons5/ArrowBack'
import Right from '@vicons/ionicons5/ArrowForward'
import Alert from '@vicons/ionicons5/Alert'
import { useMessage } from 'naive-ui'
import { useStore } from 'vuex'
import { ref } from 'vue'
import { scrollToTop } from '@/utils/composables'

import config from '../shared/config'
import jwtInterceptor from '../shared/jwt.interceptor'


export default {
  components: {
    Left,
    Right,
    Alert
  },
  setup() {
    const store = useStore()
    const showModalRef = ref(false)
    const userMessageRef = ref('')
    const message = useMessage()
    const disableSendButtonRef = ref(false)
    function getPrevAndNextKeysRelativeToMenuKey(key) {

      // fetch menu
      const menuNested = store.getters['menu/getMenu']

      // first lets flatten the whole nested menu structure
      function flattenArray(a, r) {
        a.forEach(({children, ...rest}) => {
          // save only if has path
          // this means that its a clickable menu item
          // some root menu items can have path and might
          // also have children. IT BREAKS IN THAT CASE
          (rest.path && typeof children == 'undefined') ? r.push(rest) : null

          if (children) flattenArray(children, r)
        });
      }
      var menu = [];
      flattenArray(menuNested, menu)

      // find the prev and next keys of current key
      for (let i = 0; i < menu.length; i++) {
        if (menu[i].key === key) {
          // check if first or last menu item
          const prev = i < 1 ? menu[i].key : menu[i-1].key
          const next = i == menu.length -1 ? menu[i].key : menu[i+1].key

          const prevPath = i < 1 ? menu[i].path : menu[i-1].path
          const nextPath = i == menu.length -1 ? menu[i].path : menu[i+1].path

          return [prev, next, prevPath, nextPath]
        }
      }
    }
    function next() {
      scrollToTop()
      const currentKey = this.store.getters['menu/getCurrentMenuSelection']
      const [ , next ,, nextPath ] = getPrevAndNextKeysRelativeToMenuKey(currentKey)
      // console.log(currentKey, next)
      store.commit('menu/setCurrentMenuSelection', next)
      // console.log(next, nextPath)
      store.commit('userData/updateUserDataLessonLastVisit', nextPath)
    }
    function prev() {
      scrollToTop()
      const currentKey = this.store.getters['menu/getCurrentMenuSelection']
      const [ prev ,,  prevPath , ] = getPrevAndNextKeysRelativeToMenuKey(currentKey)
      // console.log(currentKey, prev)
      store.commit('menu/setCurrentMenuSelection', prev)
      // console.log(prev, prevPath)
      store.commit('userData/updateUserDataLessonLastVisit', prevPath)
    }

    // user message

    async function sendUserMessage_(payload) {
      const response = await jwtInterceptor
        .post(config.backend_server + "accounts/sendMessageFromUserToEmail", payload)
        .catch((error) => {
          console.log(error)
          return error.response
        })
      console.log(response)
      return response
    }

    function sendUserMessage() {

      // lets validate the message
      if (userMessageRef.value.length < 5) {
        message.warning('Žinutė per trumpa.')
        return
      }

      if (userMessageRef.value.length > 10000) {
        message.warning('Žinutė per ilga.')
        return
      }

      const metadata = {
        'tema': store.getters['menu/getCurrentMenuData'].tema,
        'potemė': store.getters['menu/getCurrentMenuData'].poteme,
        'naudotojo id': store.getters['auth/getAuthData'].userId,
        'naudotojo vardas': store.getters['auth/getAuthData'].userName,
        'naudotojo el. paštas': store.getters['auth/getAuthData'].email,
      }
      console.log(metadata)
      console.log(userMessageRef.value)

      // sent the message to the backend
      const payload = { 'message': userMessageRef.value, 'metadata': metadata }
      disableSendButtonRef.value = true
      sendUserMessage_(payload).then(resp => {
        if (resp.status == 200) {
          message.success('Ačiū, pranešimas išsiųstas.')
          showModalRef.value = false
          disableSendButtonRef.value = false
        } else {
          message.warning(JSON.stringify(resp))
          disableSendButtonRef.value = false
        }
      })

    }

    return {
      getPrevAndNextKeysRelativeToMenuKey,
      showModal: showModalRef,
      message,
      store,
      next,
      prev,

      userMessage: userMessageRef,
      disableSendButton: disableSendButtonRef,
      sendUserMessage,
    }
  }
}
</script>
