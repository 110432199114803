<template>
    <n-layout-sider
      v-if="!(isMobile || isTablet)"
      :native-scrollbar="false"
      bordered
      collapse-mode="width"
      :collapsed-width="64"
      :width="300"
    >
      <Menu />
    </n-layout-sider>
</template>

<script>
import { defineComponent } from 'vue'
import { useIsMobile, useIsTablet } from '@/utils/composables'
import Menu from '@/components/Menu.vue'

const isMobileRef = useIsMobile()
const isTabletRef = useIsTablet()

export default defineComponent({
  components: { Menu },
  setup () {
    return {
      isMobile: isMobileRef,
      isTablet: isTabletRef,
    }
  }
})
</script>
