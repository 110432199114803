<template>
  <div>
    <n-form
    :model="model"
    :rules="rules"
    ref="formRef"
    >
      <!-- Emitting the array of selected values to the parent.
      This emit is setting the "selectedAnswer" array to
      the v-model object of the parent which in this case is
      "userSelectedAnswers[content_._id]" -->
      <n-form-item path="checkboxGroupValue">
        <n-checkbox-group
          v-for="answer in answers"
          :key="answer"
          v-model:value="model.checkboxGroupValue"
          @update:value="$emit('update:modelValue', model.checkboxGroupValue)"
        >
          <n-space item-style="display: flex;" :size="[35, 35]">
            <n-checkbox type="info" :label="answer.label" :value="answer._id" :size="'large'">
              <div style="white-space: initial; line-height: 1.3em;">
                {{ answer.label }}
              </div>
            </n-checkbox>
          </n-space>
        </n-checkbox-group>
    </n-form-item>
    </n-form>
  </div>
</template>

<script>

import { ref } from 'vue'

export default {
  name: 'answersComp',
  props: {
    answers: Array[String],
    selectedAnswers: Array[String],
  },
  setup(props) {
    const formRef = ref(null)
    const model = ref({ checkboxGroupValue: props.selectedAnswers })
    const rules = {
      checkboxGroupValue: {
        type: 'array',
        required: true,
        trigger: 'change',
        message: 'Pasirinkite bent vieną atsakymo variantą'
      },
    }
    function handleValidateClick () {
      // e.preventDefault()

      // this or gets an error for some reason this is not
      // yet initiated...
      if (!formRef.value) { return }
      formRef.value.validate((errors) => {
        if (!errors) {
          // message.success('Valid')
        } else {
          // message.error('Pasirinkite visus atsakymo variantus')
        }
      })
    }
    return {
      // selectedAnswer: selectedAnswerRef,
      model,
      rules,
      formRef,
      handleValidateClick,
    }
  }
}
</script>

<style scoped>

/* This has to do with checkbox render */
:deep(.n-checkbox__label){
  flex:1;
  font-size: 14px;
}

/* this has to do with form items being listed vertically, instead of horizontally */
:deep(.n-form-item .n-form-item-blank){
  display: inline
}
</style>
