<template>
  <n-el> <!-- This provides global css vars -->
  <n-space vertical :size="30">
    <template v-for="(content_, index) in currentContent_.content" v-bind:key="content_.type">
      <n-grid x-gap="32" :cols="isAdmin ? showHideRenderComponent[content_._id] ? 2: 1 : 1">
        <n-gi>
          <n-card
            v-if="isAdmin ? showHideRenderComponent[content_._id] : true"
            :index="index"
            hoverable
            :title="content_.title"
            :focused="true"
            :id="content_.title"
            :segmented="{content: true}"
            :data-sal="content_.data.length > 5000 ? '' : 'slide-up'"
            data-sal-duration="500"
          >
          <template #header v-if="content_.title">
            <div @click="store.dispatch('cardExpand/changeCardState', {lessonId: currentContent_._id, cardId: content_._id})" style="cursor: pointer">
              {{content_.title}}
            </div>
          </template>
          <n-collapse-transition :show="!store.getters['cardExpand/isCardHidden']({lessonId: currentContent_._id, cardId: content_._id})">

            <!-- html from the editor -->
            <div v-if="content_.type == 'md'" v-html="content_.data" class="content"></div>

            <!-- complex -->
            <div v-if="content_.type == 'complex'">
              <div v-if="!globalComponents[content_.data]">
                component is not found: {{ content_.data }}
              </div>
              <component
                v-bind:is="globalComponents[content_.data]"
                :params="content_.params ? jsonParser(content_.params) : null
              "/>
            </div>

            <!-- answers-->
            <div v-if="content_.answers">
              <answersComp
                :ref="setItem"
                v-model="userSelectedAnswers[content_._id]"
                :selectedAnswers="userSelectedAnswers[content_._id]"
                :answers="content_.answers"
              />
              <br>
              <template
                v-if="showCorrectWrong[content_._id] &&
                Object.keys(showCorrectWrong[content_._id]).length != 0"
              >
                <n-alert title="Atsakymas teisingas" type="success">
                </n-alert>
              </template>
              <template v-else
              >
                <n-alert v-if="buttonWasClicked" title="Atsakymas neteisingas" type="warning">
                </n-alert>
              </template>
            </div>

            <!-- flip cards -->
            <div v-if="content_.flipCards">
              <flipCards_ :cards_="content_.flipCards" />
            </div>

          </n-collapse-transition>
          </n-card>
        </n-gi>
        <n-gi v-if="isAdmin">
          <n-space vertical>
            <n-input-group horizontal size="small">
              <n-input v-model:value="content_.title" type="text" placeholder="Kortelės pavadinimas"/>
              <n-dropdown
                @select="addBlock"
                trigger="click"
                :options="[
                  {label: 'teksto kortelė', key: 1, id: content_._id, index: index},
                  {label: 'klausimo kortelė', key: 2, id: content_._id, index: index},
                  {label: 'praktinės užduoties kortelė', key: 3, id: content_._id, index: index},
                  {label: 'objekto kortelė', key: 4, id: content_._id, index: index},
                ]"
              >
                <n-tooltip trigger="hover">
                  <template #trigger>
                      <n-button><template #icon><n-icon><add-outline /></n-icon></template></n-button>
                  </template>
                  Pridėti naują kortelę
                </n-tooltip>
              </n-dropdown>
              <n-tooltip trigger="hover">
                <template #trigger>
                  <n-popconfirm
                    @positive-click="removeBlock(index)"
                    positive-text="Taip"
                    negative-text="Ne"
                  >
                    <template #trigger>
                      <n-button><template #icon><n-icon><close-outline /></n-icon></template></n-button>
                    </template>
                    Ar tikrai norite ištrinti šią kortelę?
                  </n-popconfirm>
                </template>
                Ištrinti kortelę
              </n-tooltip>
              <n-tooltip trigger="hover">
                <template #trigger>
                  <n-button @click="showHideRenderComponent[content_._id] = !showHideRenderComponent[content_._id]" :type="showHideRenderComponent[content_._id] ? 'primary' : 'default'">
                    <template #icon><n-icon><people-outline /></n-icon></template>
                  </n-button>
                </template>
                Rodyti/paslėpti kaip šią kortelę matys naudotojai
              </n-tooltip>
            </n-input-group>

            <!-- html from the editor -->
            <template v-if="content_.type == 'md'" >
              <n-space vertical>
                <ckeditor :editor="config.editor" v-model="content_.data" :config="config.editorConfig"></ckeditor>
              </n-space>
            </template>

            <!-- complex -->
            <template v-if="content_.type == 'complex'" >
              <n-space vertical>
                <!-- <n-input v-model:value="content_.title" autosize type="textarea" /> -->
                <n-input v-model:value="content_.data" autosize type="textarea" />
                <n-input v-model:value="content_.params" autosize type="textarea" />
              </n-space>
            </template>

            <!-- answers-->
            <template v-if="content_.answers" >
              <n-dynamic-input
                v-model:value="content_.answers"
                placeholder="Atsakymas"
                :on-create="onCreateAnswer"
                :min="1"
                #="{ value }"
              >
                <n-checkbox v-model:checked="value.isCorrect" style="margin-right: 12px;" />
                <n-input v-model:value="value.label" type="text" placeholder="Atsakymas" />
              </n-dynamic-input>
            </template>

            <!-- flip cards -->
            <template v-if="content_.flipCards">
              <n-dynamic-input
                v-model:value="content_.flipCards"
                preset="pair"
                key-placeholder="Kortelės priekis"
                value-placeholder="Koretelės galas"
                :on-create="onCreateFlipCard"
                :min="1"
                #="{ value }"
              />
            </template>
          </n-space>
        </n-gi>
      </n-grid>
    </template>
    <div v-if="!isAdmin && currentContent_.content">
      <n-divider dashed></n-divider>
      <div v-if="isTest">
        <n-progress
          type="line"
          :indicator-placement="'inside'"
          status="success"
          processing
          :percentage="(Object.keys(showCorrectWrong).filter(q => showCorrectWrong[q].length).length / questions.length) * 100"
        /><br>
      </div>
      <div v-if="currentContent.agreeToTermsCheckbox">
        <n-checkbox v-model:checked="formValue.checkbox">Sutinku su sąlygomis</n-checkbox><br><br>
      </div>
      <n-space>
          <n-spin size="small" :show="isLoading">
            <n-button-group horizontal>
              <n-button
                @click="updateUserProgress"
                :type="isLessonFinised ? 'primary' : 'default'"
              >
                <template #icon v-if="isLessonFinised">
                  <n-icon><checkmark-done-outline /></n-icon>
                </template>
                <template v-if="isLessonFinised">
                  {{ currentContent_.content[0].answers ? 'Testas atliktas' : 'Išmokta' }}
                </template>
                <template v-else>
                  {{ currentContent_.content[0].answers ? 'Pateikti atsakymus' : 'Pažymėti kaip išmoktą' }}
                </template>
              </n-button>

              <n-popconfirm
                style="max-width: 50%;"
                positive-button-props="type: 'warning'"
                @positive-click="deleteUserLessonData"
                @negative-click="() => {}"
                positive-text="Taip"
                negative-text="Ne"
                v-if="isLessonFinised"
              >
                <template #trigger>
                  <n-button secondary type="warning">
                    <template #icon>
                      <n-icon><close-outline /></n-icon>
                    </template>
                  </n-button>
                </template>
                  {{
                    currentContent_.content[0].answers
                      ? 'Panaikinti atsakymus į šio testo klausimus?'
                      : 'Panaikinti šios temos pažymėjimą kaip išmoktą?'
                  }}
              </n-popconfirm>

          </n-button-group>
          </n-spin>
        <navArrows />
      </n-space>
      <n-divider dashed></n-divider>
    </div>
  </n-space>
  </n-el>
</template>

<script>

import { defineAsyncComponent, ref, computed, onMounted } from 'vue'
import answersComp from '@/pages/docs/answerComponentRenderer.vue'
import { useMessage } from 'naive-ui'
import { useStore } from 'vuex'
import { CheckmarkDoneOutline  } from '@vicons/ionicons5'
import navArrows from '@/components/navArrows.vue'
import editorConfig from '@/pages/editor/editor.js'
import { AddOutline, CloseOutline, PeopleOutline } from '@vicons/ionicons5'
import flipCards_ from '@/pages/docs/globalComponents/flipCards_.vue'
import sal from "sal.js"

const globalComponents = {
  'testGlobalObj': defineAsyncComponent(() => import(`@/pages/docs/globalComponents/testGlobalObj.vue`)),
  'timeLine': defineAsyncComponent(() => import(`@/pages/docs/globalComponents/timeLine.vue`)),
  'ekonominisVertinimas': defineAsyncComponent(() => import(`@/pages/docs/globalComponents/ekonominisVertinimas.vue`)),
  'flipCards': defineAsyncComponent(() => import(`@/pages/docs/globalComponents/flipCards.vue`)),
  'flipCards_9': defineAsyncComponent(() => import(`@/pages/docs/globalComponents/flipCards_9.vue`)),
  'flipCards_2': defineAsyncComponent(() => import(`@/pages/docs/globalComponents/flipCards_2.vue`)),
  '6_modulis_pirkimo_doc_ikelimas': defineAsyncComponent(() => import(`@/pages/docs/globalComponents/6_modulis_pirkimo_doc_ikelimas.vue`)),
  'simuliacija': defineAsyncComponent(() => import(`@/pages/docs/globalComponents/simuliacija.vue`)),
}

export default {
  name: 'content',
  // inject: ['marked'],
  props: ['currentContent', 'isAdmin'],
  components: {
    answersComp,
    CheckmarkDoneOutline,
    navArrows,
    // try loading editor lazy over here
    // instead of main.js to save on space
    ckeditor: defineAsyncComponent(() => {
      return import('@ckeditor/ckeditor5-vue')
      .then(module => module.component)
    }),
    AddOutline,
    CloseOutline,
    PeopleOutline,
    flipCards_,
  },
  setup(props) {

    onMounted(() => {
      sal({ threshold: 0.025, once: true, disabled: props.isAdmin ? true : false })
    })

    const showHideRenderComponentRef = ref({})
    const config = editorConfig()
    const message = useMessage()
    const store = useStore()
    const isLoadingRef = ref(false)
    const currentContentRef = computed(() => props.currentContent)
    const isLessonFinised = computed(() => store.getters['userData/isLessonFinished'](currentContentRef.value._id))
    // bool to check if includes questions
    // computed because else on reload, this will not set properly
    const questions = computed(() => props['currentContent'].content ? props['currentContent'].content.filter(item => Object.keys(item).includes('answers')) : [])
    const isTest = computed(() => questions.value.length > 0)
    const userSelectedAnswersFromStore = computed(() => getSelectedAnswersFromUserData())
    const showCorrectWrongRef = ref(Object.assign({}, JSON.parse(JSON.stringify(userSelectedAnswersFromStore.value || {}))))
    const buttonWasClickedRef = ref(false)
    const userSelectedAnswersRef = ref(userSelectedAnswersFromStore.value)
    const answerBlockRef = ref([])
    const setItemRef = el => {
      if (el) {
        // push if not exists
        if (answerBlockRef.value.indexOf(el) === -1) answerBlockRef.value.push(el);
      }
    }

    // checkbox to agree with rules
    const formValue = ref({ checkbox: false })

    function jsonParser(jsonString) {
      try {
        return JSON.parse(jsonString)
      } catch (e) {
        console.log('invalid JSON')
        return null
      }
    }
    function getSelectedAnswersFromUserData() {
      let selectedAnswers = {}
      const userData = store.getters['userData/getUserData']
      // console.log(userData)
      const lessonData = userData.lessonData[currentContentRef.value._id]
      if (lessonData && lessonData.answers) {
        const lessonAnswers = lessonData.answers
        Object.keys(lessonAnswers).forEach(id => {
          if ('answers' in lessonAnswers[id]) {
            lessonAnswers[id] = lessonAnswers[id].answers
          }
        })
        selectedAnswers = lessonAnswers
      }
      return selectedAnswers
    }
    function updateUserProgress() {
      const numberOfAnswersSelected = Object.keys(showCorrectWrongRef.value).filter(q => showCorrectWrongRef.value[q].length).length
      const numberofQuestions = questions.value.length
      console.log(isLessonFinised.value, numberOfAnswersSelected, numberofQuestions, numberOfAnswersSelected == numberofQuestions)
      if (
        isLessonFinised.value &&
        // if new questions are added after used has finished the lesson, lets allow to submit.
        // To allow that, see if all questions are answered. If not than allow to push the botton.
        (Object.keys(showCorrectWrongRef.value).filter(q => showCorrectWrongRef.value[q].length).length == questions.value.length)
      ) {
        message.success('Ši pamoka jau pažymėta kaip pabaigta')
        return null
      }

      if (currentContentRef.value.agreeToTermsCheckbox) {
        if (!formValue.value.checkbox) {
          message.warning('Privaloma sutikti su sąlygomis! Pažymėkite varnelę.')
          return
        }
      }

      // check if user has selected the answers
      if (isTest.value) {
        const q_ids = questions.value.map(item => item._id)
        const a_ids = Object.keys(userSelectedAnswersRef.value).filter(key => userSelectedAnswersRef.value[key].length)
        const allAnswersAreIncluded = q_ids.every(function (item) { return a_ids.includes(item) })
        answerBlockRef.value.forEach(item => item.handleValidateClick())

        // should check for errors returned, but cant get it working
        // the returned array is always the same because valdiate function
        // reutnrs before completion
        if (allAnswersAreIncluded) {
          console.log('no errors, lets check answers')
        } else {
          message.warning('Atsakėte į ne visus klausimus')
          return null
        }
      }

      const payload = {
        'userId': store.getters['auth/getAuthData'].userId,
        'lessonId': currentContentRef.value._id,
        'answers': isTest.value ? userSelectedAnswersRef.value : null
      }
      // console.log('lessonID', lessonId)
      isLoadingRef.value = true
      store.dispatch('userData/updateUserLessonData', payload)
      .then((resp) => {
        if (resp) {
          isLoadingRef.value = false

          // set bools if test
          if (isTest.value) {
            showCorrectWrongRef.value = getSelectedAnswersFromUserData()
            buttonWasClickedRef.value = true
            const lessonData = store.getters['userData/getUserData'].lessonData[currentContentRef.value._id]
            if ((lessonData) && ('isFinished' in lessonData) && (lessonData.isFinished)) {
              message.success('Sveikiname! Visi atsakymai teisingi.')
            } else {
              message.warning('Ne visi pateikti atsakymai yra teisingi.')
            }
          }
        }
      })
    }
    function deleteUserLessonData() {
      isLoadingRef.value = true
      store.dispatch('userData/deleteUserLessonData', {
        userId: store.getters['auth/getAuthData'].userId,
        lessonId: currentContentRef.value._id
      }).then(() => {

        // remove user selection
        Object.keys(userSelectedAnswersRef.value).forEach(key => {
          userSelectedAnswersRef.value[key] = []
        })
        // remove the actual selection object inside the quesion box
        for (const obj of answerBlockRef.value) {
          obj.model.checkboxGroupValue = []
          obj.formRef.model.checkboxGroupValue = []
        }

        // remove correct / wrong fields
        showCorrectWrongRef.value = {}
        buttonWasClickedRef.value = false

        isLoadingRef.value = false
      })
    }
    return {
      config,
      onCreateAnswer () {
        return {
          isCorrect: false,
          label: ''
        }
      },
      onCreateFlipCard () {
        return {
          key: '',
          value: ''
        }
      },
      showHideRenderComponent: showHideRenderComponentRef,
      buttonWasClicked: buttonWasClickedRef,
      showCorrectWrong: showCorrectWrongRef,
      userSelectedAnswersFromStore,
      currentContent_: currentContentRef,
      globalComponents,
      jsonParser,
      updateUserProgress,
      deleteUserLessonData,
      userSelectedAnswers: userSelectedAnswersRef,
      answerBlock: answerBlockRef,
      setItem: setItemRef,
      store: store,
      isLoading: isLoadingRef,
      isLessonFinised: isLessonFinised,
      questions,
      isTest,
      formValue,
      flipCards_,
    }
  },
  methods: {
    removeBlock(index) {
      // in case there's only one block, do not let delete it
      if (this.currentContent_.content.length == 1) { return }
      this.currentContent_.content.splice(index, 1)
      console.log('removing', index)
    },
    addBlock(key, option) {

      // find current index
      // const index = this.currentContent_.content.findIndex(block => {
      //   return block._id == option.id
      // })

      // list all possible blocks
      // const simpleBlock = {type: 'md', title: '/', data: '/'}
      // const questionBlock = {type: 'md', title: '/', data: '/', answers: [{'isCorrect': false, 'label': ''}]}
      // const complexBlock = {type: 'complex', title: '/', data: '/'}

      // create new block
      let newBlock = null
      const blockType = option.label
      switch (blockType) {
        case 'teksto kortelė':
          newBlock = {type: 'md', title: '/', data: '/'}
          break
        case 'klausimo kortelė':
          newBlock = {type: 'md', title: '/', data: '/', answers: [{'isCorrect': false, 'label': ''}]}
          break
        case 'objekto kortelė':
          newBlock = {type: 'complex', title: '/', data: '/'}
          break
        case 'praktinės užduoties kortelė':
          newBlock = {type: 'md', title: '/', data: '/', flipCards: [{key: '', value: ''}]}
          break
        default:
        newBlock = {type: 'md', title: '/', data: '/'}
      }

      // add to lesson
      this.currentContent_.content.splice(option.index + 1, 0, newBlock)
      console.log('adding', option, key)
    }
  }
}
</script>

<style scoped>

  /* Why do we use deep? This is because of v-html.
  In orred to pass styles to that component */

  .content :deep(img:hover) {
    transform: scale(1.1);

    /* https://stackoverflow.com/questions/14677490/blurry-text-after-using-css-transform-scale-in-chrome/43138551 */
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  .content :deep(.redBox:hover) {
    transform: scale(1.015);
  }

  .content :deep(.greenBox:hover) {
    transform: scale(1.015);
  }

  .content :deep(.yellowBox:hover) {
    transform: scale(1.015);
  }

  .content :deep(.grayBox:hover) {
    transform: scale(1.015);
  }

</style>
