<template>
  <n-space>
    <ul class="flashcard-list">
      <li v-on:click="toggleCard(card)" v-for="(card, index) in cards" :key="index">
        <transition name="flip">
          <div>
          <p v-bind:key="card.flipped" class="card" style="overflow-wrap: break-word;">
            {{ card.flipped ? card.value : card.key }}
          </p>
        </div>
        </transition>
      </li>
    </ul>
  </n-space>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    'cards_': Array
  },
  setup(props) {
    return {
      cards: ref(props.cards_),
      newFront: '',
      newBack: '',
      error: false
    }
  },

  methods: {
    toggleCard: function(card) {
      if (card.flipped == undefined) {card.flipped = false}
      card.flipped = !card.flipped;
    },
    addNew: function() {
      if(!this.newFront || !this.newBack) {
        this.error = true;
      } else {
        this.cards.push({
          key: this.newFront,
          value: this.newBack,
          flipped: false
        });
        // set the field empty
        this.key = '';
        this.value = '';
        // Make the warning go away
        this.error= false;
      }
    }
  }
});
</script>

<style scoped>
body {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }

  ul {
    padding-left: 0;
    display: flex;
    flex-flow: row wrap;
  }

  li {
    list-style-type: none;
    padding: 10px 10px;
    transition: all 0.3s ease;
  }

  .container {
    max-width: 100%;
    padding: 2em;
  }

  .card {
    display: block;

    width: 200px;
    min-height: 100px;

    padding: 10px 10px;
    /* background-color: #fdb913; */
    border: solid #fdb913 4px;
    border-radius: 7px;
    margin: 5px;
    text-align: center;
    line-height: 27px;
    cursor: pointer;
    position: relative;
    /* color: #fff; */
    /* font-weight: 600; */
    /* font-size: 20px; */
    -webkit-box-shadow: 9px 10px 22px -8px rgba(209,193,209,.5);
    -moz-box-shadow: 9px 10px 22px -8px rgba(209,193,209,.5);
    box-shadow: 9px 10px 22px -8px rgba(209,193,209,.5);
    /* will-change: transform; */
  }

  li:hover{
    transform: scale(1.025);
  }

  .flip-enter-active,  {
    transition: all 0.4s ease;
  }

  .flip-leave-active {
    display: none;
    /* transition: all 0.4s cubic-bezier(1.0, 0.5, 0.8, 1.0); */
  }

  .flip-enter, .flip-leave {
    transform: rotateY(180deg);
    opacity: 0;

  }

  /* Form */
  .flashcard-form{
    position: relative;
  }

  label{
    font-weight: 400;
    color: #333;
    margin-right: 10px;
  }

  input{
    border-radius: 5px;
    border: 2px solid #eaeaea;
    padding: 10px;
    outline: none;
  }

  button{
    border-radius: 5px;
    border: 1px solid #87cb84;
    background-color: #87cb84;
    padding: 8px 15px;
    outline: none;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  button:hover{
    background-color: #70a66f;
  }

  .error{
    margin-top: 10px;
    display: block;
    color: #e44e42;
    font-weight: 600;
  }


</style>
