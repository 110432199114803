<template>
  <n-menu
    :indent="16"
    :root-indent="16"
    :collapsed-width="64"
    :collapsed-icon-size="12"
    :render-extra="renderMenuExtra"
    :options="updatedMenu"
    @update:value="handleUpdateValue"
    :value="store.getters['menu/getCurrentMenuSelection']"
    @update:expanded-keys="handleExpandKeyClick"
    :expanded-keys="expandedKeys"
    :default-expand-all="false"
    style="width: 305px;"
    :style="loadingBar.loadingBarRef._value.loading ? 'pointer-events: none;' : ''"
  />
</template>

<script>
import { defineComponent, ref, watch, watchEffect, h, computed } from 'vue'
import { useStore } from 'vuex'
import { scrollToTop } from '@/utils/composables'
import { CheckmarkSharp  } from '@vicons/ionicons5'
import { NIcon, NBadge } from 'naive-ui'
import { useLoadingBar } from 'naive-ui'

// https://stackoverflow.com/questions/68955025/how-to-get-the-value-of-each-parent-from-a-nested-array-of-object
//
const getParentKey = (object, search) => {
  if (object.key === search) return [object.key];
  else if ((object.children) || Array.isArray(object)) {
    let children = Array.isArray(object) ? object : object.children;
    for (let child of children) {
      let result = getParentKey(child, search);
      if (result) {
        if (object.key) result.unshift(object.key);
        return result;
      }
    }
  }
}

export default defineComponent({
  name: 'Menu',
  setup (_, { emit }) {
    const store = useStore()
    const loadingBar = useLoadingBar()

    // ---------------------
    // use watchEffect
    const updatedLessonsRef = ref([])

    watchEffect(() => {

      // this must be inside watch, else it will run but not run again on change ?!
      const menuRef = ref(store.getters['menu/getMenu'])
      const userDataRef = ref(store.getters['userData/getUserData'])
      const authDataRef = ref(store.getters['auth/getAuthData'])
      const lastLogin = authDataRef.value.lastLogin

      // if the data is not yet ready, return and set to empty array
      if (!menuRef.value || !userDataRef.value || !authDataRef.value) {
        updatedLessonsRef.value = []
        return
      }

      function flat(array) {
        var result = [];
        array.forEach(function (a) {
          result.push(a);
          if (Array.isArray(a.children)) {
            result = result.concat(flat(a.children));
          }
        });
        return result;
      }

      const flatMenu = flat(menuRef.value)

      const updatedLessons = flatMenu.filter(item => {
        if (item.lastUpdate) {
          // check if updated after last visit OR
          // if updated after last login if never visited before
          const lessonData = userDataRef.value.lessonData[item.path] || {}
          const visitTime = new Date(lessonData.lastVisit || lastLogin)
          // have to convert from local to UTC
          const visitTimeUTC = Date.UTC(
            visitTime.getUTCFullYear(),
            visitTime.getUTCMonth(),
            visitTime.getUTCDate(),
            visitTime.getUTCHours(),
            visitTime.getUTCMinutes(),
            visitTime.getUTCSeconds()
          )
          // console.log(item.lastUpdate, visitTimeUTC, item.path, item.lastUpdate > visitTime)
          if (item.lastUpdate > visitTimeUTC) {
            return item.path
          }
        }
      })

      // console.log('updated', updatedLessons.map(item => item.path))
      updatedLessonsRef.value = updatedLessons.map(item => item.path)
    })
    // // ---------------------

    // this renders the extra yellow dot near the name
    // it takes an array of updatedPaths and sets the dot
    // const updatedLessons = computed(() => store.getters['lessons/getUpdatedLessons'])
    function renderMenuExtra (option) {
      // check if children is part of updated lessons
      let updatedChild = false
      if (option.children) {
        updatedChild = option.children.some(c => updatedLessonsRef.value.includes(c.path))
      }
      // check if node has updated lessons
      if ((updatedLessonsRef.value.includes(option.path)) || (updatedChild) ){
        const options = {
          processing: true,
          dot: true,
          type: 'success',
          value: 'nauja',
          style: 'font-size: 50%;'
        }
        return h(NBadge, options, () => h('div', {
          // style: 'padding-top: 10px; padding-left: 18px; font-size: 50%;'
          style: 'padding-top: 15px; padding-left: 2px;'
        }, ''))
      }
    }

    // this whole thing seems complicated and it is
    // the whole reason for this is to update expanded keys
    // when user clicks next and also to allow to control
    // expanded keys manually
    // related things:
    // handleExpandKeyClick
    // @update:expanded-keys="handleExpandKeyClick"
    // :expanded-keys="expandedKeys"
    // https://stackoverflow.com/questions/67775761/vue3-composition-api-watch-store-value
    const expandedKeysRef = ref([])
    watch( () => store.getters['menu/getCurrentMenuSelection'], function() {
       const currentParent = getParentKey(store.getters['menu/getMenu'], store.getters['menu/getCurrentMenuSelection'])
       // check if key is there and push if not. Else the same kes will accumulate on every change
       // expandedKeysRef.value.indexOf(currentParent[0]) === -1 ? expandedKeysRef.value.push(currentParent[0]) : false
       expandedKeysRef.value = [currentParent[0]] // keep only one expanded or many (the line above)
    })

    // storing this inside computed prevents this to be triggered on every menu click
    const updatedMenuRef = computed(() => addFinishedIconsToMenu(store.getters['menu/getMenu']))

    function addFinishedIconsToMenu(menu) {
      console.log('addFinishedIconsToMenu')
      const userData = store.getters['userData/getUserData']

      // extract finished lessons
      const finishedLessonIds = []
      for (const [key, value] of Object.entries(userData.lessonData)) {
        if (value.isFinished) {
          finishedLessonIds.push(key)
        }
      }

      function renderIcon (icon) {
        return () => h(NIcon, null, () => h(icon))
      }

      function markFinishedMenuLessons(menu) {
        return menu.map(obj => {
          if (!obj.children || (obj.children && obj.children.length == 0)) {
            if (finishedLessonIds.includes(obj.path)) {
              obj.icon = renderIcon(CheckmarkSharp)
            } else {
              delete obj.icon
            }
          } else {
            markFinishedMenuLessons(obj.children);
          }
          return obj;
        })
      }

      // if all children are finished mark parent as finished
      function markFinishedMenuParents(menu) {
        return menu.map(obj => {
          if (obj.children && (obj.children && obj.children.length > 0)) {
            const allChildrenAreFinished = obj.children.every((child) => { return 'icon' in child })
            if (allChildrenAreFinished) {
              obj.icon = renderIcon(CheckmarkSharp)
            } else {
              delete obj.icon
            }
          }
          return obj;
        })
      }

      let menu_ = markFinishedMenuLessons(menu)
      menu_ = markFinishedMenuParents(menu_)
      // console.log(menu_)
      return menu_
    }

    return {
      expandedKeys: expandedKeysRef,
      getParentKey,
      store,
      loadingBar,
      handleUpdateValue (key, item) {
        // when changing this one do not formet to change NAVARROWS
        emit('menuClick')
        store.commit('menu/setCurrentMenuSelection', key)
        scrollToTop()
        // remove updatedlesson from the list
        // store.commit('lessons/removeUpdatedLesson', item.path)
        store.commit('userData/updateUserDataLessonLastVisit', item.path)
      },
      handleExpandKeyClick (keys) {
        expandedKeysRef.value = keys
      },
      addFinishedIconsToMenu,
      updatedMenu: updatedMenuRef,
      renderMenuExtra,
    }
  }
})
</script>
