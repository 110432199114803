// https://ckeditor.com/docs/ckeditor5/latest/framework/guides/tutorials/implementing-a-block-widget.html

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import { toWidget, toWidgetEditable } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import Command from '@ckeditor/ckeditor5-core/src/command';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

export default class yellowBox extends Plugin {
    static get requires() {
        return [ yellowBoxEditing, yellowBoxUI ];
    }
}

class yellowBoxUI extends Plugin {
    init() {
        // console.log( 'yellowBoxUI#init() got called' );

        const editor = this.editor;
        const t = editor.t;

        // The "yellowBox" button must be registered among the UI components of the editor
        // to be displayed in the toolbar.
        editor.ui.componentFactory.add( 'yellowBox', locale => {
            // The state of the button will be bound to the widget command.
            const command = editor.commands.get( 'insertyellowBox' );

            // The button will be an instance of ButtonView.
            const buttonView = new ButtonView( locale );

            buttonView.set( {
                // The t() function helps localize the editor. All strings enclosed in t() can be
                // translated and change when the language of the editor changes.
                label: t( '🟨' ),
                withText: true,
                tooltip: 'Geltona kortelė'
            } );

            // Bind the state of the button to the command.
            buttonView.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );

            // Execute the command when the button is clicked (executed).
            this.listenTo( buttonView, 'execute', () => editor.execute( 'insertyellowBox' ) );

            return buttonView;
        } );
    }
}

class yellowBoxEditing extends Plugin {
    static get requires() {
        return [ Widget ];
    }

    init() {
        // console.log( 'yellowBoxEditing#init() got called' );

        this._defineSchema();
        this._defineConverters();

        this.editor.commands.add( 'insertyellowBox', new InsertyellowBoxCommand( this.editor ) );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register( 'yellowBox', {
            // Behaves like a self-contained object (e.g. an image).
            isObject: false,

            // Allow in places where other blocks are allowed (e.g. directly in the root).
            allowWhere: '$block'
        } );

        schema.register( 'yellowBoxTitle', {
            // Cannot be split or left by the caret.
            isLimit: true,

            allowIn: 'yellowBox',

            // Allow content which is allowed in blocks (i.e. text with attributes).
            allowContentOf: '$block'
        } );

        schema.register( 'yellowBoxDescription', {
            // Cannot be split or left by the caret.
            isLimit: true,

            allowIn: 'yellowBox',

            // Allow content which is allowed in the root (e.g. paragraphs).
            allowContentOf: '$root'
        } );

        schema.addChildCheck( ( context, childDefinition ) => {
            if ( context.endsWith( 'yellowBoxDescription' ) && childDefinition.name == 'yellowBox' ) {
                return false;
            }
        } );
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        // <yellowBox> converters
        conversion.for( 'upcast' ).elementToElement( {
            model: 'yellowBox',
            view: {
                name: 'section',
                classes: 'yellowBox'
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'yellowBox',
            view: {
                name: 'section',
                classes: 'yellowBox'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'yellowBox',
            view: ( modelElement, { writer: viewWriter } ) => {
                const section = viewWriter.createContainerElement( 'section', { class: 'yellowBox' } );

                return toWidget( section, viewWriter, { label: 'simple box widget' } );
            }
        } );

        // <yellowBoxTitle> converters
        conversion.for( 'upcast' ).elementToElement( {
            model: 'yellowBoxTitle',
            view: {
                name: 'h1',
                classes: 'simple-box-title'
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'yellowBoxTitle',
            view: {
                name: 'h1',
                classes: 'simple-box-title'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'yellowBoxTitle',
            view: ( modelElement, { writer: viewWriter } ) => {
                // Note: You use a more specialized createEditableElement() method here.
                const h1 = viewWriter.createEditableElement( 'h1', { class: 'simple-box-title' } );

                return toWidgetEditable( h1, viewWriter );
            }
        } );

        // <yellowBoxDescription> converters
        conversion.for( 'upcast' ).elementToElement( {
            model: 'yellowBoxDescription',
            view: {
                name: 'div',
                classes: 'simple-box-description'
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'yellowBoxDescription',
            view: {
                name: 'div',
                classes: 'simple-box-description'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'yellowBoxDescription',
            view: ( modelElement, { writer: viewWriter } ) => {
                // Note: You use a more specialized createEditableElement() method here.
                const div = viewWriter.createEditableElement( 'div', { class: 'simple-box-description' } );

                return toWidgetEditable( div, viewWriter );
            }
        } );
    }
}

class InsertyellowBoxCommand extends Command {
    execute() {
        this.editor.model.change( writer => {
            // Insert <yellowBox>*</yellowBox> at the current selection position
            // in a way that will result in creating a valid model structure.
            this.editor.model.insertContent( createyellowBox( writer ) );
        } );
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent( selection.getFirstPosition(), 'yellowBox' );

        this.isEnabled = allowedIn !== null;
    }
}

function createyellowBox( writer ) {
    const yellowBox = writer.createElement( 'yellowBox' );
    const yellowBoxTitle = writer.createElement( 'yellowBoxTitle' );
    const yellowBoxDescription = writer.createElement( 'yellowBoxDescription' );

    // default value
    writer.insertText('📌 Svarbu', yellowBoxTitle)

    writer.append( yellowBoxTitle, yellowBox );
    writer.append( yellowBoxDescription, yellowBox );

    // There must be at least one paragraph for the description to be editable.
    // See https://github.com/ckeditor/ckeditor5/issues/1464.
    writer.appendElement( 'paragraph', yellowBoxDescription );

    return yellowBox;
}
