
// ⚠️ NOTE: We don't use @ckeditor/ckeditor5-build-classic any more!
// Since we're building CKEditor from source, we use the source version of ClassicEditor.
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';

// image
import Image from '@ckeditor/ckeditor5-image/src/image';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
// IMPORTANT LOOK, THIS IS CUSTOM PACKAGE FROM SRC
import ImageInsert from '@/pages/editor/imageinsert';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageTextAlternative from '@ckeditor/ckeditor5-image/src/imagetextalternative';

// table
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';

// headings
import Heading from '@ckeditor/ckeditor5-heading/src/heading';

// lists
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';

// custom plugins
import redBox from '@/pages/editor/boxes/redBox.js'
import grayBox from '@/pages/editor/boxes/grayBox.js'
import greenBox from '@/pages/editor/boxes/greenBox.js'
import yellowBox from '@/pages/editor/boxes/yellowBox.js'

export const editorConfig = (editorDataRef) => {
  return {
    editor: ClassicEditor,
    editorData: editorDataRef,
    editorConfig: {
      plugins: [
        EssentialsPlugin,
        BoldPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        Alignment,
        MediaEmbed,
        Subscript,
        Underline,
        Highlight,

        // image
        Image,
        Base64UploadAdapter,
        ImageInsert,
        ImageCaption,
        ImageResize,
        ImageStyle,
        ImageToolbar,
        ImageTextAlternative,
        // ImageUpload,

        // table
        Table,
        TableToolbar,
        TableProperties,
        TableCellProperties,

        // headings
        Heading,

        // lists
        ListStyle,

        // custom plugins
        redBox,
        grayBox,
        greenBox,
        yellowBox,

      ],
      toolbar: {
        items: [
          'heading',
          'bold',
          'italic',
          'underline',
          'subscript',
          'bulletedList',
          'numberedList',
          'alignment',
          // 'fontColor',
          'highlight',

          '|',
          'link',
          'mediaEmbed',
          'imageInsert',
          'insertTable',
          '|',
          'redBox',
          'grayBox',
          'greenBox',
          'yellowBox',
        ],
      },

      highlight: {
        options: [
            { model: 'yellowMarker', class: 'marker-yellow', title: 'Geltona', color: 'var(--ck-highlight-marker-yellow)', type: 'marker' },
            { model: 'greenMarker', class: 'marker-green', title: 'Žalia', color: 'var(--ck-highlight-marker-green)', type: 'marker' },
            { model: 'blueMarker', class: 'marker-blue', title: 'Mėlyna', color: 'var(--ck-highlight-marker-blue)', type: 'marker' },
        ]
      },

      link: {
        decorators: {
          openInNewTab: {
            mode: 'manual',
            label: 'Atidaryti naujame lange',
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          }
        }
      },

      table: {
        contentToolbar: [
          'tableColumn', 'tableRow', 'mergeTableCells',
          'tableProperties', 'tableCellProperties'
        ],
      },

      image: {
        toolbar: ['imageStyle:inline', 'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight', '|', 'imageTextAlternative' ],
        // defaultItem: 'imageStyle:inline',
        resizeUnit: 'px',
      },

      // !IMPORTANT
      // Withought it the media will not render
      // https://stackoverflow.com/questions/57490383/ckeditor5-how-to-insert-youtube-video
      mediaEmbed: {
        previewsInData: true,
        resizeUnit: 'px',
      }
    }
  }
}

export default editorConfig;
