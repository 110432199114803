// https://ckeditor.com/docs/ckeditor5/latest/framework/guides/tutorials/implementing-a-block-widget.html

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import { toWidget, toWidgetEditable } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import Command from '@ckeditor/ckeditor5-core/src/command';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

export default class grayBox extends Plugin {
    static get requires() {
        return [ grayBoxEditing, grayBoxUI ];
    }
}

class grayBoxUI extends Plugin {
    init() {
        // console.log( 'grayBoxUI#init() got called' );

        const editor = this.editor;
        const t = editor.t;

        // The "grayBox" button must be registered among the UI components of the editor
        // to be displayed in the toolbar.
        editor.ui.componentFactory.add( 'grayBox', locale => {
            // The state of the button will be bound to the widget command.
            const command = editor.commands.get( 'insertgrayBox' );

            // The button will be an instance of ButtonView.
            const buttonView = new ButtonView( locale );

            buttonView.set( {
                // The t() function helps localize the editor. All strings enclosed in t() can be
                // translated and change when the language of the editor changes.
                label: t( '⬛' ),
                withText: true,
                tooltip: 'Pilka kortelė'
            } );

            // Bind the state of the button to the command.
            buttonView.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );

            // Execute the command when the button is clicked (executed).
            this.listenTo( buttonView, 'execute', () => editor.execute( 'insertgrayBox' ) );

            return buttonView;
        } );
    }
}

class grayBoxEditing extends Plugin {
    static get requires() {
        return [ Widget ];
    }

    init() {
        // console.log( 'grayBoxEditing#init() got called' );

        this._defineSchema();
        this._defineConverters();

        this.editor.commands.add( 'insertgrayBox', new InsertgrayBoxCommand( this.editor ) );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register( 'grayBox', {
            // Behaves like a self-contained object (e.g. an image).
            isObject: false,

            // Allow in places where other blocks are allowed (e.g. directly in the root).
            allowWhere: '$block'
        } );

        schema.register( 'grayBoxTitle', {
            // Cannot be split or left by the caret.
            isLimit: true,

            allowIn: 'grayBox',

            // Allow content which is allowed in blocks (i.e. text with attributes).
            allowContentOf: '$block'
        } );

        schema.register( 'grayBoxDescription', {
            // Cannot be split or left by the caret.
            isLimit: true,

            allowIn: 'grayBox',

            // Allow content which is allowed in the root (e.g. paragraphs).
            allowContentOf: '$root'
        } );

        schema.addChildCheck( ( context, childDefinition ) => {
            if ( context.endsWith( 'grayBoxDescription' ) && childDefinition.name == 'grayBox' ) {
                return false;
            }
        } );
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        // <grayBox> converters
        conversion.for( 'upcast' ).elementToElement( {
            model: 'grayBox',
            view: {
                name: 'section',
                classes: 'grayBox'
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'grayBox',
            view: {
                name: 'section',
                classes: 'grayBox'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'grayBox',
            view: ( modelElement, { writer: viewWriter } ) => {
                const section = viewWriter.createContainerElement( 'section', { class: 'grayBox' } );

                return toWidget( section, viewWriter, { label: 'simple box widget' } );
            }
        } );

        // <grayBoxTitle> converters
        conversion.for( 'upcast' ).elementToElement( {
            model: 'grayBoxTitle',
            view: {
                name: 'h1',
                classes: 'simple-box-title'
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'grayBoxTitle',
            view: {
                name: 'h1',
                classes: 'simple-box-title'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'grayBoxTitle',
            view: ( modelElement, { writer: viewWriter } ) => {
                // Note: You use a more specialized createEditableElement() method here.
                const h1 = viewWriter.createEditableElement( 'h1', { class: 'simple-box-title' } );

                return toWidgetEditable( h1, viewWriter );
            }
        } );

        // <grayBoxDescription> converters
        conversion.for( 'upcast' ).elementToElement( {
            model: 'grayBoxDescription',
            view: {
                name: 'div',
                classes: 'simple-box-description'
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'grayBoxDescription',
            view: {
                name: 'div',
                classes: 'simple-box-description'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'grayBoxDescription',
            view: ( modelElement, { writer: viewWriter } ) => {
                // Note: You use a more specialized createEditableElement() method here.
                const div = viewWriter.createEditableElement( 'div', { class: 'simple-box-description' } );

                return toWidgetEditable( div, viewWriter );
            }
        } );
    }
}

class InsertgrayBoxCommand extends Command {
    execute() {
        this.editor.model.change( writer => {
            // Insert <grayBox>*</grayBox> at the current selection position
            // in a way that will result in creating a valid model structure.
            this.editor.model.insertContent( creategrayBox( writer ) );
        } );
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent( selection.getFirstPosition(), 'grayBox' );

        this.isEnabled = allowedIn !== null;
    }
}

function creategrayBox( writer ) {
    const grayBox = writer.createElement( 'grayBox' );
    const grayBoxTitle = writer.createElement( 'grayBoxTitle' );
    const grayBoxDescription = writer.createElement( 'grayBoxDescription' );

    // default value
    writer.insertText('✒️ Apibrėžimas', grayBoxTitle)

    writer.append( grayBoxTitle, grayBox );
    writer.append( grayBoxDescription, grayBox );

    // There must be at least one paragraph for the description to be editable.
    // See https://github.com/ckeditor/ckeditor5/issues/1464.
    writer.appendElement( 'paragraph', grayBoxDescription );

    return grayBox;
}
