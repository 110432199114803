<template>
  <div id="topToScrollTo"></div>
  <n-space vertical :size="30">
    <n-h1 prefix="bar" type="primary">
      <template v-if="getCurrentMenuData.tema">
        {{getCurrentMenuData.tema || '-'}}
      </template>
      <template v-else>
        <n-spin size="small" />
      </template>
    </n-h1>
    <n-h2 prefix="bar" type="success" style="margin-top: -25px;">{{getCurrentMenuData.poteme || '-'}}</n-h2>

    <template v-if="materialIsLoading || (Object.keys(getCurrentMenuData.data).length == 0)">
      <n-skeleton height="20px;" style="width: 160px; margin-top: -25px;" :sharp="false" />
    </template>
    <template v-else>
      <p style="margin-top: -25px; font-weight: 200; font-size: smaller;">
        {{ currentContent.updated ? 'Atnaujinta: ' + parseDate(currentContent.updated) : '' }}
      </p>
    </template>

    <!-- if backend call is dispatched or after login the data is not yet returned then skeleton -->
    <template v-if="materialIsLoading || (Object.keys(getCurrentMenuData.data).length == 0)">
      <n-space vertical>
          <n-skeleton height="60px" :sharp="false" />
          <n-skeleton height="200px" :sharp="false" /><br>
          <n-skeleton height="60px" :sharp="false" />
          <n-skeleton height="200px" :sharp="false" /><br>
          <n-skeleton height="60px" :sharp="false" />
          <n-skeleton height="200px" :sharp="false" /><br>
      </n-space>
    </template>
    <template v-else>
      <contentRenderer :currentContent="currentContent" :isAdmin="adminView"/>
    </template>
  </n-space>
</template>

<script>

import { ref } from 'vue'
import { useIsMobile, useIsTablet } from '@/utils/composables'
import { useStore } from 'vuex'
import { useLoadingBar } from 'naive-ui'
import contentRenderer from '@/pages/docs/contentRenderer'
// import navArrows from '@/components/navArrows.vue'

const isMobileRef = useIsMobile()
const isTabletRef = useIsTablet()

export default {
  name: 'doc',
  props: {
    adminView: {
      type: Boolean,
      default: false
    }
  },
  components: {
    contentRenderer,
    // navArrows
  },
  setup() {
    const store = useStore()
    const currentContentRef = ref({})
    const materialIsLoadingRef = ref(false)
    const loadingBar = useLoadingBar()
    loadingBar.loadingBarRef.value.cssVars['--n-height'] = "5px"
    const firstLoad = true

    return {
      store,
      loadingBar,
      firstLoad,
      currentContent: currentContentRef,
      materialIsLoading: materialIsLoadingRef,
      isMobile: isMobileRef,
      isTablet: isTabletRef,
    }
  },
  computed: {
    getCurrentMenuData() {
      const params = this.parseUrlHash()
      params ? this.setCurrentMenuIfUrlHash(params) : false
      const currentMenuData = this.$store.getters['menu/getCurrentMenuData']
      if (currentMenuData.data.path) {
        this.setCurrentContent(currentMenuData.data.path)
      }
      return currentMenuData
    }
  },
  methods: {
    setCurrentContent(path) {
      this.materialIsLoading = true
      !this.firstLoad ? this.loadingBar.start() : null
      this.store.dispatch('lessons/getLesson', path).then((data) => {
        if (data.status != 200) {
          !this.firstLoad ? this.loadingBar.error() : null
        }
        this.currentContent = data.data
        this.materialIsLoading = false
        !this.firstLoad ? this.loadingBar.finish() : null
        this.firstLoad = false
      })
    },

    // these are for parsing url hash and redirecting
    parseUrlHash() {
      const fullpath = this.$route.fullPath
      console.log('parsing', fullpath)
      if (fullpath) {
        const params = { modulis: null, tema: null }
        // in case it fails
        try {
          const urlParams = decodeURI(fullpath).split("#")
          // set modulis and tema labels
          // will be undefined if no params given in url
          params.modulis = urlParams[1]
          params.tema = urlParams[2]
        } catch (error) {
          console.log(error)
        }
        return params
      }
    },
    setCurrentMenuIfUrlHash(params) {
      const menu = this.store.getters['menu/getMenu']
      const modulis = menu.filter(item => item.label == params.modulis)
      let tema = null
      if (modulis.length && modulis[0].children) {
        tema = modulis[0].children.filter(item => item.label == params.tema)
      }

      // if has only modulis but not tema
      if (modulis.length && !tema.length) {
        let key = modulis[0].key // will be undefined or valid if no children
        if (modulis[0].children.length > 0) {
          key = modulis[0].children[0].key
        }
        if (key) {
          this.store.commit('menu/setCurrentMenuSelection', key)
          this.$router.fullPath = ''
          window.location.hash = '' // this to let move elswhere from new menu item
          return
        }
      }

      // if has tema
      if (tema) {
        this.store.commit('menu/setCurrentMenuSelection', tema[0].key)
        this.$router.fullPath = ''
        window.location.hash = '' // this to let move elswhere from new menu item
        return
      }
    },

    parseDate(date) {
      let d = new Date(Date.parse(date));
      return d.toLocaleString('sv-SE')
    }

  },
}
</script>

<style>
</style>
